<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form ref="form" v-model="isValid" :disabled="!isActive">
      <v-card-text>
        <v-alert v-if="!isActive" type="warning" icon="mdi-delete">{{
          $t("utilization.utilizationIsDeleted")
        }}</v-alert>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          :items="nmoList"
          :label="
            getTranslation('utilization.creationForm.formFieldTitles.nmo', '*')
          "
          required
          v-model="utilization.nmoId"
          item-text="name"
          item-value="id"
          outlined
          :loading="isNMODataLoading"
          :rules="[
            () =>
              !!utilization.nmoId ||
              $t('utilization.creationForm.errors.nmoRequired'),
          ]"
          :disabled="!isUserAdmin"
          v-if="isUserAdmin"
        ></v-select>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          :items="htcList"
          :label="
            getTranslation('utilization.creationForm.formFieldTitles.htc', '*')
          "
          required
          v-model="utilization.htcId"
          item-text="name"
          item-value="id"
          outlined
          :disabled="htcList.length < 1 || isUserDoctor"
          :loading="isHTCDataLoading"
          v-if="!isUserDoctor"
        ></v-select>
        <v-autocomplete
          dense
          hide-details=""
          class="pb-2"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.patient',
              '*'
            )
          "
          required
          outlined
          v-model="utilization.patientId"
          :items="patientList"
          item-text="patientId"
          item-value="patientId"
          :search-input.sync="patientSearch"
          :loading="isPatientDataLoading"
          :disabled="utilization.nmoId == null"
          hide-no-data
        ></v-autocomplete>
        <v-menu
          dense
          hide-details=""
          class="pb-2"
          ref="menu"
          v-model="menuDoB"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              hide-details=""
              class="pb-2"
              append-icon
              v-model="treatmentDate"
              :label="
                getTranslation(
                  'utilization.creationForm.formFieldTitles.treatmentDate',
                  '*'
                )
              "
              required
              readonly
              outlined
              v-on="on"
              v-bind="attrs"
              :rules="[
                () =>
                  !!utilization.treatmentDate ||
                  $t('utilization.creationForm.errors.treatmentDateRequired'),
              ]"
            ></v-text-field>
          </template>
          <v-date-picker
            dense
            hide-details=""
            class="pb-2"
            ref="picker"
            show-adjacent-months
            v-model="treatmentDate"
            @input="menuDoB = false"
            :max="new Date(Date.now()).toISOString().substr(0, 10)"
            min="1963-01-01"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
          dense
          hide-details=""
          class="pb-2"
          :items="lotNumbersList"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.lotNumber',
              '*'
            )
          "
          required
          :loading="isLotNumberDataLoading"
          v-model="utilization.lotNumberId"
          outlined
          item-text="productName"
          item-value="id"
          :search-input.sync="lotNumberSearch"
          :rules="[
            () =>
              !!utilization.lotNumberId ||
              $t('utilization.creationForm.errors.lotNumberRequired'),
          ]"
          :disabled="!canLoadLotNumbers"
          :no-data-text="$t('utilization.creationForm.hint.noLotNumber')"
          :filter="
            (item, queryText, itemText) =>
              item.productName.toLowerCase().indexOf(queryText.toLowerCase()) >
                -1 ||
              item.lNumber.toLowerCase().indexOf(queryText.toLowerCase()) > -1
          "
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.lNumber }} ({{ item.productName }})
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("product.creationForm.formFieldTitles.expiryDate") }}:
                {{ item.expiryDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title
                >{{ item.lNumber }} ({{ item.productName }})</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ $t("product.creationForm.formFieldTitles.expiryDate") }}:{{
                  item.expiryDate
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-checkbox
          dense
          hide-details=""
          class="pb-2 mt-1"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.inhibitors'
            )
          "
          v-model="utilization.inhibitors"
        ></v-checkbox>
        <v-checkbox
          dense
          hide-details=""
          class="pb-4 mt-1"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.homeTreatment'
            )
          "
          v-model="utilization.homeTreatment"
        ></v-checkbox>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.treatmentType',
              '*'
            )
          "
          v-model="utilization.treatmentTypeId"
          required
          :items="treatmentTypeList"
          item-text="name"
          item-value="id"
          outlined
          :loading="isTreatmentTypeDataLoading"
          :rules="[
            () =>
              !!utilization.treatmentTypeId ||
              $t('utilization.creationForm.errors.treatmentTypeRequired'),
          ]"
        ></v-select>
        <v-select
          dense
          hide-details=""
          class="pb-2"
          v-if="utilization.treatmentTypeId == 2"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.acuteBleedType',
              '*'
            )
          "
          :loading="isBleedTypeDataLoading"
          :items="bleedTypeList"
          item-text="name"
          item-value="id"
          v-model="utilization.acuteBleedTypeId"
          outlined
          :rules="[
            () =>
              (utilization.treatmentTypeId == 2 &&
                !!utilization.acuteBleedTypeId) ||
              $t('utilization.creationForm.errors.acuteBleedTypeRequired'),
          ]"
        ></v-select>
        <!-- If treatmentTypeId is Surgery or other -->
        <v-textarea
          dense
          hide-details=""
          class="pb-2"
          v-if="
            utilization.treatmentTypeId == 1 || utilization.treatmentTypeId == 4
          "
          outlined
          :label="
            utilization.treatmentTypeId == 1
              ? getTranslation(
                  'utilization.creationForm.formFieldTitles.surgeryDescription'
                )
              : getTranslation(
                  'utilization.creationForm.formFieldTitles.treatmentDescription'
                )
          "
          v-model="utilization.treatmentDescription"
        ></v-textarea>

        <v-text-field
          dense
          hide-details=""
          class="pb-2 hide-arrow"
          :label="
            getTranslation(
              'utilization.creationForm.formFieldTitles.totalUIUsed',
              '*'
            )
          "
          required
          v-model="utilization.totalUIUsed"
          outlined
          type="number"
          :rules="[
            () =>
              !!utilization.totalUIUsed ||
              $t('utilization.creationForm.errors.totalUIUsedRequired'),
            () =>
              utilization.totalUIUsed > 0 ||
              $t('utilization.creationForm.errors.totalUIUsedInvalid'),
          ]"
        ></v-text-field>
      </v-card-text>
    </v-form>
    <div class="d-flex">
      <v-spacer />
      <v-btn
        v-if="isActive"
        color="success"
        class="mr-4"
        @click="save()"
        :disabled="isSending || !isValid"
        :loading="isSending"
      >
        {{ $t("actions.save") }}
      </v-btn>
      <v-btn
        v-if="showDeleteUtilization"
        color="error"
        @click="deleteUtilisation"
        :loading="isSending"
        >{{ $t("actions.delete") }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["parameter"],
  data() {
    return {
      bleedTypeList: [],
      htcList: [],
      isBleedTypeDataLoading: false,
      isHTCDataLoading: false,
      isLotNumberDataLoading: false,
      isNMODataLoading: false,
      isPatientDataLoading: false,
      isSending: false,
      isShowContent: false,
      isTreatmentTypeDataLoading: false,
      isValid: false,
      lotNumbersList: [],
      menuDoB: false,
      nmoList: [],
      patientExists: false,
      patientSearch: null,
      lotNumberSearch: null,
      rawPatientList: [],
      showSnackbar: false,
      showSnackbarColor: "success",
      snackBarMessage: "SAVED",
      treatmentTypeList: [],
      utilization: { treatmentDate: this.$moment.utc().format() },
    };
  },
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    autoFillLocationIdentifiers() {
      const htcId = this.$store.getters.getHTCId;
      const nmoId = this.$store.getters.getNMOId;

      this.utilization = { ...this.utilization, htcId, nmoId };
    },
    close() {
      this.$store.commit("hideModal");
    },

    deleteUtilisation() {
      if (confirm(this.$t("utilization.confirmDelete"))) {
        this.isSending = true;
        axios
          .delete(
            `${this.$store.getters.getEnvironment}utilization/${this.utilization.id}`
          )
          .then((res) => {
            if (res.data) this.utilization.isActive = false;
          })
          .finally(() => {
            this.isSending = false;
          });
      }
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    insert() {
      this.isSending = true;
      axios
        .post(
          this.$store.getters.getEnvironment + "Utilization",
          this.utilization
        )
        .then((response) => {
          this.isSending = false;
          this.$store.commit("setUtilizationKey");
          this.close();
        })
        .catch((e) => {})
        .finally(() => {
          this.isSending = false;
        });
    },
    async load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      await axios
        .get(
          this.$store.getters.getEnvironment +
            "utilization/" +
            this.parameter.id
        )
        .then((response) => {
          this.utilization = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadBleedTypes() {
      this.isBleedTypeDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(`${this.$store.getters.getEnvironment}acuteBleedType`)
        .then((res) => {
          let types = res.data;
          types = types.map((x) => {
            x.name = this.$t(`utilization.acuteBleedTypes.${x.name}`);
            return x;
          });

          this.bleedTypeList = types;
        })
        .catch((e) => {})
        .finally(() => {
          this.isBleedTypeDataLoading = false;
        });
    },
    async loadNMOs() {
      this.isNMODataLoading = this.$store.state.loadingBarColor;
      await axios
        .get(`${this.$store.getters.getEnvironment}nmo?nameOnly=true`)
        .then((res) => {
          this.nmoList = res.data;
          this.isNMODataLoading = false;
        })
        .catch((e) => {});
    },
    async loadHTCs() {
      if (this.utilization.nmoId) {
        this.isHTCDataLoading = this.$store.state.loadingBarColor;
        await axios
          .get(
            `${this.$store.getters.getEnvironment}htc?nameOnly=true&nmoId=${this.utilization.nmoId}`
          )
          .then((res) => {
            this.isHTCDataLoading = false;
            this.htcList = res.data;
          })
          .catch((e) => {});
      }
    },
    loadLotNumbers() {
      function strcmp(a, b) {
        if (a.toString() < b.toString()) return -1;
        if (a.toString() > b.toString()) return 1;
        return 0;
      }
      if (this.utilization.nmoId && this.utilization.htcId) {
        this.isLotNumberDataLoading = this.$store.state.loadingBarColor;

        axios
          .get(
            `${this.$store.getters.getEnvironment}lotNumber?nmoId=${this.utilization.nmoId}&minExpiryDate=${this.treatmentDate}&isUsable=true&htcId=${this.utilization.htcId}`
          )
          .then((res) => {
            this.isLotNumberDataLoading = false;
            this.lotNumbersList = res.data;
            this.lotNumbersList = this.lotNumbersList.sort((a, b) =>
              strcmp(a.productName, b.productName)
            );
          })
          .catch((e) => {});
      }
    },
    async loadPatients() {
      if (this.utilization.nmoId && this.utilization.htcId) {
        this.isPatientDataLoading = this.$store.state.loadingBarColor;
        var xhr = axios.get(
          `${this.$store.getters.getEnvironment}patient?nmoId=${this.utilization.nmoId}&htcId=${this.utilization.htcId}&isUtilizationSelection=true`
        );
        await xhr
          .then((res) => {
            this.isPatientDataLoading = false;
            this.rawPatientList = res.data;
          })
          .catch((e) => {});
      }
    },

    loadTreatmentTypes() {
      this.isTreatmentTypeDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(`${this.$store.getters.getEnvironment}treatmentType`)
        .then((res) => {
          let types = res.data;

          types = types.map((x) => {
            x.name = this.$t(`utilization.treatmentTypes.${x.name}`);
            return x;
          });
          this.treatmentTypeList = types;
        })
        .catch((e) => {})
        .finally(() => {
          this.isTreatmentTypeDataLoading = false;
        });
    },
    parseDate(date) {
      if (!date) {
        return this.$moment.utc().format("YYYY-MM-DD");
      }

      var f = this.$moment.utc(date).format("YYYY-MM-DD");

      return f;
    },
    save() {
      this.utilization.totalUIUsed = Number(this.utilization.totalUIUsed);
      if (this.utilization.id == null) this.insert();
      else this.update();
    },
    update() {
      this.isSending = true;
      axios
        .put(
          this.$store.getters.getEnvironment +
            "Utilization/" +
            this.utilization.id,
          this.utilization
        )
        .then((response) => {
          //this.utilization.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setUtilizationKey");
          this.close();
        })
        .catch((e) => {});
    },
    validatePatientExistence() {
      this.isPatientDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(
          `${this.$store.getters.getEnvironment}patient/search?patientId=${this.utilization.patientId}`
        )
        .then((res) => {
          this.isPatientDataLoading = false;
          this.patientExists = false; // res.data.any;
          //this.$refs["patientId"].validate();
        })
        .catch((e) => {});
    },
  },
  created() {
    this.onSetTitle(this.$t("utilization.view.edit"));

    this.loadNMOs();
    this.loadTreatmentTypes();
    this.loadBleedTypes();
    if (this.parameter.id != -1) {
      this.load();
    } else {
      this.autoFillLocationIdentifiers();
    }
  },
  computed: {
    canLoadLotNumbers() {
      return (
        this.utilization.nmoId != null &&
        this.utilization.treatmentDate != null &&
        this.utilization.htcId != null
      );
    },
    htcId() {
      return this.utilization.htcId;
    },
    isActive() {
      if (this.isNew) return true;
      return this.utilization.isActive;
    },
    isNew() {
      return this.utilization.creationDate == null;
    },
    isUserAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    isUserNMOAdmin() {
      return this.$store.getters.getIsNMOAdmin;
    },
    isUserDoctor() {
      return !this.isUserAdmin && !this.isUserNMOAdmin;
    },
    nmoId() {
      return this.utilization.nmoId;
    },

    patientList() {
      return this.rawPatientList;
    },

    treatmentDate: {
      get: function() {
        var d = this.parseDate(this.utilization.treatmentDate);

        return d;
      },
      set: function(val) {
        this.utilization.treatmentDate = this.$moment.utc(val).format();
      },
    },
    showDeleteUtilization() {
      var canShowDeleteButton = this.isActive && !this.isNew;
      return this.$store.getters.getCanDeleteUtilization && canShowDeleteButton;
    },
    sortedLotNumberList() {
      function strcmp(a, b) {
        if (a.toString() < b.toString()) return -1;
        if (a.toString() > b.toString()) return 1;
        return 0;
      }
      let cp = [].concat(this.lotNumbersList);
      cp = cp.sort.sort((a, b) => strcmp(a.productName, b.productName));
      return cp;
    },
  },
  watch: {
    nmoId: function(newVal, oldVal) {
      this.loadHTCs();
      this.loadPatients();
      if (this.treatmentDate) {
        this.loadLotNumbers();
      }
    },
    htcId: function(newVal, oldVal) {
      this.loadPatients();
      if (this.treatmentDate) {
        this.loadLotNumbers();
      }
    },
    treatmentDate: function() {
      if (this.nmoId) {
        this.loadLotNumbers();
      }
    },
  },
};
</script>
