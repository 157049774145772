var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%","min-height":"100%"}},[_c('v-form',{ref:"form",attrs:{"disabled":!_vm.isActive},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-text',[(!_vm.isActive)?_c('v-alert',{attrs:{"type":"warning","icon":"mdi-delete"}},[_vm._v(_vm._s(_vm.$t("utilization.utilizationIsDeleted")))]):_vm._e(),(_vm.isUserAdmin)?_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","items":_vm.nmoList,"label":_vm.getTranslation('utilization.creationForm.formFieldTitles.nmo', '*'),"required":"","item-text":"name","item-value":"id","outlined":"","loading":_vm.isNMODataLoading,"rules":[
          function () { return !!_vm.utilization.nmoId ||
            _vm.$t('utilization.creationForm.errors.nmoRequired'); } ],"disabled":!_vm.isUserAdmin},model:{value:(_vm.utilization.nmoId),callback:function ($$v) {_vm.$set(_vm.utilization, "nmoId", $$v)},expression:"utilization.nmoId"}}):_vm._e(),(!_vm.isUserDoctor)?_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","items":_vm.htcList,"label":_vm.getTranslation('utilization.creationForm.formFieldTitles.htc', '*'),"required":"","item-text":"name","item-value":"id","outlined":"","disabled":_vm.htcList.length < 1 || _vm.isUserDoctor,"loading":_vm.isHTCDataLoading},model:{value:(_vm.utilization.htcId),callback:function ($$v) {_vm.$set(_vm.utilization, "htcId", $$v)},expression:"utilization.htcId"}}):_vm._e(),_c('v-autocomplete',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.patient',
            '*'
          ),"required":"","outlined":"","items":_vm.patientList,"item-text":"patientId","item-value":"patientId","search-input":_vm.patientSearch,"loading":_vm.isPatientDataLoading,"disabled":_vm.utilization.nmoId == null,"hide-no-data":""},on:{"update:searchInput":function($event){_vm.patientSearch=$event},"update:search-input":function($event){_vm.patientSearch=$event}},model:{value:(_vm.utilization.patientId),callback:function ($$v) {_vm.$set(_vm.utilization, "patientId", $$v)},expression:"utilization.patientId"}}),_c('v-menu',{ref:"menu",staticClass:"pb-2",attrs:{"dense":"","hide-details":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pb-2",attrs:{"dense":"","hide-details":"","append-icon":"","label":_vm.getTranslation(
                'utilization.creationForm.formFieldTitles.treatmentDate',
                '*'
              ),"required":"","readonly":"","outlined":"","rules":[
              function () { return !!_vm.utilization.treatmentDate ||
                _vm.$t('utilization.creationForm.errors.treatmentDateRequired'); } ]},model:{value:(_vm.treatmentDate),callback:function ($$v) {_vm.treatmentDate=$$v},expression:"treatmentDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDoB),callback:function ($$v) {_vm.menuDoB=$$v},expression:"menuDoB"}},[_c('v-date-picker',{ref:"picker",staticClass:"pb-2",attrs:{"dense":"","hide-details":"","show-adjacent-months":"","max":new Date(Date.now()).toISOString().substr(0, 10),"min":"1963-01-01"},on:{"input":function($event){_vm.menuDoB = false}},model:{value:(_vm.treatmentDate),callback:function ($$v) {_vm.treatmentDate=$$v},expression:"treatmentDate"}})],1),_c('v-autocomplete',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","items":_vm.lotNumbersList,"label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.lotNumber',
            '*'
          ),"required":"","loading":_vm.isLotNumberDataLoading,"outlined":"","item-text":"productName","item-value":"id","search-input":_vm.lotNumberSearch,"rules":[
          function () { return !!_vm.utilization.lotNumberId ||
            _vm.$t('utilization.creationForm.errors.lotNumberRequired'); } ],"disabled":!_vm.canLoadLotNumbers,"no-data-text":_vm.$t('utilization.creationForm.hint.noLotNumber'),"filter":function (item, queryText, itemText) { return item.productName.toLowerCase().indexOf(queryText.toLowerCase()) >
              -1 ||
            item.lNumber.toLowerCase().indexOf(queryText.toLowerCase()) > -1; }},on:{"update:searchInput":function($event){_vm.lotNumberSearch=$event},"update:search-input":function($event){_vm.lotNumberSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.lNumber)+" ("+_vm._s(item.productName)+") ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("product.creationForm.formFieldTitles.expiryDate"))+": "+_vm._s(item.expiryDate)+" ")])],1)]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.lNumber)+" ("+_vm._s(item.productName)+")")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("product.creationForm.formFieldTitles.expiryDate"))+":"+_vm._s(item.expiryDate)+" ")])],1)]}}]),model:{value:(_vm.utilization.lotNumberId),callback:function ($$v) {_vm.$set(_vm.utilization, "lotNumberId", $$v)},expression:"utilization.lotNumberId"}}),_c('v-checkbox',{staticClass:"pb-2 mt-1",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.inhibitors'
          )},model:{value:(_vm.utilization.inhibitors),callback:function ($$v) {_vm.$set(_vm.utilization, "inhibitors", $$v)},expression:"utilization.inhibitors"}}),_c('v-checkbox',{staticClass:"pb-4 mt-1",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.homeTreatment'
          )},model:{value:(_vm.utilization.homeTreatment),callback:function ($$v) {_vm.$set(_vm.utilization, "homeTreatment", $$v)},expression:"utilization.homeTreatment"}}),_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.treatmentType',
            '*'
          ),"required":"","items":_vm.treatmentTypeList,"item-text":"name","item-value":"id","outlined":"","loading":_vm.isTreatmentTypeDataLoading,"rules":[
          function () { return !!_vm.utilization.treatmentTypeId ||
            _vm.$t('utilization.creationForm.errors.treatmentTypeRequired'); } ]},model:{value:(_vm.utilization.treatmentTypeId),callback:function ($$v) {_vm.$set(_vm.utilization, "treatmentTypeId", $$v)},expression:"utilization.treatmentTypeId"}}),(_vm.utilization.treatmentTypeId == 2)?_c('v-select',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.acuteBleedType',
            '*'
          ),"loading":_vm.isBleedTypeDataLoading,"items":_vm.bleedTypeList,"item-text":"name","item-value":"id","outlined":"","rules":[
          function () { return (_vm.utilization.treatmentTypeId == 2 &&
              !!_vm.utilization.acuteBleedTypeId) ||
            _vm.$t('utilization.creationForm.errors.acuteBleedTypeRequired'); } ]},model:{value:(_vm.utilization.acuteBleedTypeId),callback:function ($$v) {_vm.$set(_vm.utilization, "acuteBleedTypeId", $$v)},expression:"utilization.acuteBleedTypeId"}}):_vm._e(),(
          _vm.utilization.treatmentTypeId == 1 || _vm.utilization.treatmentTypeId == 4
        )?_c('v-textarea',{staticClass:"pb-2",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.utilization.treatmentTypeId == 1
            ? _vm.getTranslation(
                'utilization.creationForm.formFieldTitles.surgeryDescription'
              )
            : _vm.getTranslation(
                'utilization.creationForm.formFieldTitles.treatmentDescription'
              )},model:{value:(_vm.utilization.treatmentDescription),callback:function ($$v) {_vm.$set(_vm.utilization, "treatmentDescription", $$v)},expression:"utilization.treatmentDescription"}}):_vm._e(),_c('v-text-field',{staticClass:"pb-2 hide-arrow",attrs:{"dense":"","hide-details":"","label":_vm.getTranslation(
            'utilization.creationForm.formFieldTitles.totalUIUsed',
            '*'
          ),"required":"","outlined":"","type":"number","rules":[
          function () { return !!_vm.utilization.totalUIUsed ||
            _vm.$t('utilization.creationForm.errors.totalUIUsedRequired'); },
          function () { return _vm.utilization.totalUIUsed > 0 ||
            _vm.$t('utilization.creationForm.errors.totalUIUsedInvalid'); } ]},model:{value:(_vm.utilization.totalUIUsed),callback:function ($$v) {_vm.$set(_vm.utilization, "totalUIUsed", $$v)},expression:"utilization.totalUIUsed"}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),(_vm.isActive)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":_vm.isSending || !_vm.isValid,"loading":_vm.isSending},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")]):_vm._e(),(_vm.showDeleteUtilization)?_c('v-btn',{attrs:{"color":"error","loading":_vm.isSending},on:{"click":_vm.deleteUtilisation}},[_vm._v(_vm._s(_vm.$t("actions.delete")))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }